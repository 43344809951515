import React from 'react';
import styled from 'styled-components';

const Markdown = styled.div`
  color: var(--text-black);
  font-size: var(--font-size-medium);
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0 10px;
    padding: 0;
    font-weight: bold;
    cursor: text;
    position: relative;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    color: var(--text-gray);
    font-size: 14px;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  li,
  table,
  pre {
    margin: 15px 0;
  }
`;

const wrapMarkup = html => ({
  __html: html
});

export default ({ content }) => (
  <Markdown className="markdown" dangerouslySetInnerHTML={wrapMarkup(content)} />
);
